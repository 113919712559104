//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseIcon from '~/components/atoms/base-icon/BaseIcon';

export default {
  name: 'NotificationMessage',
  components: {
    BaseIcon,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'success',
      validator(type) {
        return ['success', 'error'].includes(type);
      },
    },
    formId: {
      type: String,
      default: '',
    },
    dismissable: {
      type: Boolean,
      default: true,
    },
    hasMargin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isVisible: true,
      pagePath: this.$route.path,
    };
  },
  computed: {
    cIconType() {
      let icon = '';

      switch (this.$props.type) {
        case 'success':
          icon = 'checked';
          break;
        case 'error':
          icon = 'close';
          break;
        default:
        // code block
      }

      return icon;
    },
  },
  mounted() {
    this.scrollIntoView();
    if (this.$props.formId) this.triggerGTMFormSubmissionEvt();
  },
  methods: {
    triggerGTMFormSubmissionEvt() {
      if (this.$props.formId) {
        const eventObj = {
          event: 'form_submission',
          form_id: this.$props.formId,
          page_path: this.pagePath,
        };

        this.$gtm.push(eventObj);
      }
      // clear any saved form id from localStorage that was saved for the purposes of tracking form submissions (current only being set in get-in-touch page)
      this.clearStoredSubmittedFormId();
    },
    clearStoredSubmittedFormId() {
      localStorage.removeItem('submittedFormId');
    },
    closeMessage(event) {
      this.isVisible = false;
    },
    scrollIntoView() {
      this.$nextTick(() => {
        if (this.$refs.notificationMessage) {
          setTimeout(() => {
            this.$refs.notificationMessage.scrollIntoView({
              behavior: 'smooth',
            });
          }, 500);
        }
      });
    },
  },
};

import { render, staticRenderFns } from "./FormComponent.vue?vue&type=template&id=30b98808&"
import script from "./FormComponent.vue?vue&type=script&lang=js&"
export * from "./FormComponent.vue?vue&type=script&lang=js&"
import style0 from "./FormComponent.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseImage: require('/usr/src/web-www/components/atoms/base-image/BaseImage.vue').default,NotificationMessage: require('/usr/src/web-www/components/molecules/notification-message/NotificationMessage.vue').default})

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('client-only',{attrs:{"placeholder":"Loading..."}},[_c('section',{staticClass:"component-section component-section__pb-0 form-module",attrs:{"id":_vm.formIdentifier}},[(!_vm.formSubmitted)?[_c('div',{staticClass:"form-module__image-wrapper"},[_c('BaseImage',_vm._b({staticClass:"form-module__background"},'BaseImage',_vm.image,false))],1)]:_vm._e(),_vm._v(" "),_c('div',{staticClass:"container"},[(_vm.formSubmitted)?_c('NotificationMessage',{attrs:{"title":_vm.notificationMessage.title,"message":_vm.notificationMessage.message,"form-id":_vm.getFormId,"dismissable":false,"has-margin":true}}):_c('div',{staticClass:"grid"},[(_vm.hubspotFormId && _vm.hubspotFormsScriptLoaded)?_c('div',{class:[
            'form-module__form-container',
            {
              'form-module__form-container--right': _vm.$props.align == 'right',
            } ]},[(_vm.$slots['pre-form'])?_c('div',{staticClass:"form-module__pre-form-content"},[_vm._t("pre-form")],2):_vm._e(),_vm._v(" "),_c('script',[_vm._v("\n            window.hbspt.forms.create({\n              region: 'eu1',\n              portalId: '26636097',\n              formId: "+_vm._s(("\"" + (_vm.$props.hubspotFormId) + "\""))+",\n              onFormSubmit($form) {\n                window.parent.postMessage({\n                  cmd: 'formSuccess'\n                }, '*');\n              }\n            });\n          ")])]):_vm._e(),_vm._v(" "),(_vm.hubspotFormId && !_vm.hubspotFormsScriptLoaded)?_c('div',{class:[
            'form-module__form-container',
            {
              'form-module__form-container--right': _vm.$props.align == 'right',
            } ]},[_c('h5',[_vm._v("Form loading...")])]):_vm._e(),_vm._v(" "),(!_vm.hubspotFormId && _vm.formUrl)?_c('div',{class:[
            'form-module__form-container',
            {
              'form-module__form-container--right': _vm.$props.align == 'right',
            } ]},[(_vm.$slots['pre-form'])?_c('div',{staticClass:"form-module__pre-form-content"},[_vm._t("pre-form")],2):_vm._e(),_vm._v(" "),_c('iframe',{ref:"pardot",staticClass:"form-module__iframe",staticStyle:{"border":"0"},attrs:{"id":"pardot","title":"contact form","src":_vm.$props.formUrl,"width":"100%","type":"text/html","frameborder":"0","allowTransparency":"true","scrolling":"no"}})]):_vm._e()])],1)],2)])}
var staticRenderFns = []

export { render, staticRenderFns }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import global from '@/mixins/global.js';

export default {
  name: 'FormComponent',
  mixins: [global],
  props: {
    formUrl: {
      type: String,
      default: '',
    },
    hubspotFormId: {
      type: String,
      default: '',
    },
    notificationMessage: {
      type: Object,
      default: () => {
        return {
          title: "You've submitted the form successfully.",
          message:
            'Look out for our email with everything you need to know and any further actions you may need to take.',
          type: 'success',
        };
      },
    },
    backgroundImage: {
      type: Object,
      default: () => {},
    },
    redirectTo: {
      type: String,
      default: '',
    },
    align: {
      type: String,
      default: 'left',
    },
    formIdentifier: {
      type: String,
      default: 'mg-form',
    },
  },
  data() {
    return {
      pagePath: this.$route.path,
      formSubmitted: false,
      initClientOnlyCompTimeout: undefined,
      pardot: '',
      image: this.$props.backgroundImage || {
        url: '/images/template/1440/1x/contact-form.jpg',
        alternativeText: 'Contact form',
        sourceSet: [
          {
            url: '/images/template/1920/1x/contact-form.jpg 1x, /images/template/1920/2x/contact-form.jpg 2x',
            type: 'image/jpeg',
            breakpoint: 1920,
          },
          {
            url: '/images/template/1440/1x/contact-form.jpg 1x, /images/template/1440/2x/contact-form.jpg 2x',
            type: 'image/jpeg',
            breakpoint: 1440,
          },
          {
            url: '/images/template/1280/1x/contact-form.jpg 1x, /images/template/1280/2x/contact-form.jpg 2x',
            type: 'image/jpeg',
            breakpoint: 1280,
          },
          {
            url: '/images/template/1024/1x/contact-form.jpg 1x, /images/template/1024/2x/contact-form.jpg 2x',
            type: 'image/jpeg',
            breakpoint: 1024,
          },
          {
            url: '/images/template/960/1x/contact-form.jpg 1x, /images/template/960/2x/contact-form.jpg 2x',
            type: 'image/jpeg',
            breakpoint: 960,
          },
          {
            url: '/images/template/768/1x/contact-form.jpg 1x, /images/template/768/2x/contact-form.jpg 2x',
            type: 'image/jpeg',
            breakpoint: 768,
          },
          {
            url: '/images/template/480/1x/contact-form.jpg 1x, /images/template/480/2x/contact-form.jpg 2x',
            type: 'image/jpeg',
            breakpoint: 480,
          },
          {
            url: '/images/template/360/1x/contact-form.jpg 1x, /images/template/360/2x/contact-form.jpg 2x',
            type: 'image/jpeg',
            breakpoint: 0,
          },
        ],
      },
    };
  },
  computed: {
    getFormId() {
      if (!(this.$props.formUrl || this.$props.hubspotFormId)) return;
      if (this.$props.hubspotFormId) return this.$props.hubspotFormId;
      return this.$props.formUrl.substring(
        this.$props.formUrl.lastIndexOf('/') + 1,
      );
    },
  },
  async mounted() {
    if (this.$props.formUrl) await this.initClientOnlyComp();
    if (this.$props.hubspotFormId && !this.hubspotFormsScriptLoaded)
      this.initHubspotFormsScript();
    window.addEventListener('message', this.handleMessage);
  },
  methods: {
    async initClientOnlyComp(count = 10) {
      return await new Promise((resolve) => {
        this.$nextTick(() => {
          if (this.$refs.pardot) {
            this.pardot = this.$refs.pardot;
            resolve();
          } else if (count > 0) {
            if (this.initClientOnlyCompTimeout)
              window.clearTimeout(this.initClientOnlyCompTimeout);
            resolve();

            this.initClientOnlyCompTimeout = setTimeout(() => {
              this.initClientOnlyComp(count - 1);
            }, 100);
          }
        });
      });
    },
    handleMessage(event) {
      const data = event.data;
      switch (event.data.cmd) {
        case 'setIFrameHeight': {
          const height = data.params.data;
          const heightVal = height.replace('px', '');
          const formHeight = parseInt(heightVal) + 10;
          this.pardot.height = `${formHeight}px`;
          break;
        }
        case 'formSuccess': {
          if (this.$props.redirectTo) {
            const eventObj = {
              event: 'form_submission',
              form_id: this.getFormId,
              page_path: this.pagePath,
            };

            this.$emit('formSubmissionSuccess', event);
            this.pushGTMEvent(eventObj);

            return this.$router.push({
              path: this.$props.redirectTo,
            });
          }

          this.formSubmitted = true;
          this.$emit('formSubmissionSuccess', event);
        }
      }
    },
  },
};
